<template>
  <div id="app">
    <!-- <BaiHeader :user="UserInfo"/> -->
    <BaiKong/>
    <!-- <BaiList></BaiList> -->
    <!-- <BaiImgList></BaiImgList> -->
    <BaiFooter/>


    <div>
      <button @click="getInfo">获取信息</button>
    </div>
  </div>
</template>

<script>
  // 引入axios
  import axios from 'axios'
  // 引入组件
  import BaiKong from './components/BaiKong.vue'
  import BaiFooter from './components/BaiFooter.vue'

  import BaiHeader from './components/BaiHeader.vue'
  // import BaiList from './components/BaiList.vue'
  // import BaiImgList from './components/BaiImgList.vue'

export default {
  name: 'App',
  data(){
    return{
      UserInfo:{
        'id':'1',
        'mob':'18582776999',
        'name':'不知道'
      }
    }
  },
  components: {
    BaiKong,
    BaiFooter,
    BaiHeader
    // BaiList,
    // BaiImgList
  },
  methods:{
    getInfo(){
      axios.get('/api/get_majors').then(response => {
        console.log('请求成功', response.data);
      }).catch(error => {
        console.error('请求失败', error.message);
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0px;
}
</style>
