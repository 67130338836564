<template>
  <div id="header">
    <div class="header_body">
      <!-- logo尺寸最小80*120px -->
      <div class="logo">
        <a href="#"><img src="../assets/logo.png" ></a>
      </div>
      <!-- 导航 -->
      <div class="daohang">
        <a href="#">首页</a>
        <a href="#">简介</a>
      </div>
      <!-- 登录 -->
      <div class="denglu" v-if="user && Object.keys(user).length">
        <a href="login">[ {{user.name.slice(0, 5)}} ] &nbsp;{{user.mob.slice(0, 3)}}****{{user.mob.slice(7, 11)}}</a>
        <span>|</span>
        <a href="register">退出</a>
      </div>
      <div class="denglu" v-else>
        <a href="login">登录</a>
        <span>|</span>
        <a href="register">注册</a>
      </div>
    </div>
    <!-- <div class="daohang_fu">
        <a href="#">这里</a>
        <a href="#">哈的</a>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'BaiHeader',
  props: ['user'],
  mothod(){
    console.log(this.user)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a{
    text-decoration: none;         /*取消超链接下划线 */
    animation:atguigu 1s;
    color: #FFFFFF;
  }
  a:hover {
    color: #999;                 /* 鼠标悬停时的颜色 */
  }
  .come{
    animation:atguigu 1s;
  }
  .go{
    animation:atguigu 1s reverse;
  }
  @keyframes atguigu{
    from{
      transform: translateY(-100px);
    }
    to{
      transform: translateY(0px);
    }
  }
  #header{
    width: 100%;
    height: 100px;
    margin: 0;
    padding: 0;
    border: none;
    background-color: #181B2A;
  }
  .header_body{
    min-width: 500px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .logo{
    min-width: 120px;     
    height: 80px;
    margin-top: 10px;
    float: left;
    color: #FFFFFF;
  }
   .logo img{
    width: auto;                       /* 或者具体的宽度值 */
    height: 80px;                      /* 保持宽高比 */
  }
  .daohang{
    min-width: 100px;                  /*最小列宽*/
    max-width: 800px;                  /*最大列宽*/
    height: 80px;
    margin: 10px 5px 10px 20px;
    align-items: center;               /* 块元素 垂直居中 */
    float: left;
    overflow: hidden;                  /*超出隐藏*/
  }
  .daohang a{
    height: 80px;
    margin: 0 10px;
    padding: 0 10px;
    display:flex;
    align-items: center; 
    float: left;
  }
  .daohang_fu{
    height: 60px;
    width: 100%;
    /* position: relative; */
    background-color: #33343c;
    opacity: 0.9;                    /*设置透明度*/
    margin-top: 100px;   
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .daohang_fu a{
    height: 60px;
    margin: 0 10px;
    padding: 0 10px;
    line-height: 60px;
    align-items: center; 
    float: left;
  }
  .denglu{
    max-width: 260px;
    height: 80px;
    display: flex;
    margin-top: 10px;
    align-items: center; 
    float: right;
    overflow: hidden;
  }
  .denglu span{
    color: #FFFFFF;
    margin: 0 5px;
  }
</style>
