<template>
  <div class="kong">
    <H1>开发中...</H1>
  </div>
</template>

<script>
export default {
  name: 'BaiKong',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .kong{
    width: 100%;
    height: 600px;
    color: #000;
    line-height: 600px;
    text-align: center;
    background-color: #a0b8c4;
  }
  .kong a{
    text-decoration: none;
    color: #000;
  }
  .header{
    height: 600px;
  }
  
</style>
