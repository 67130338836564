<template>
  <div class="footer">
    <li class="come">
      <a href="#">关于我们</a> 
      <a>&nbsp;&nbsp;|&nbsp;&nbsp;</a>
      <a href="#"> 网站声明</a>
    </li>
    <li class="come">
      <a href="https://beian.miit.gov.cn/">蜀ICP备2024090928号-2</a>
    </li>
  </div>
</template>

<script>
export default {
  name: 'BaiFooter',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .footer{
    height: 60px;
    background-color: #181B2A;
    margin-top: 5px;
    padding: 10px;
    color:#FFFFFF;
    font-size: 12px;
  }
  .footer li{
    list-style: none;
    text-align: center;
  }
  a{
    text-decoration: none;         /*取消超链接下划线 */
    animation:atguigu 1s;
    color: #FFFFFF;
  }
  a:hover {
    color: #999;                 /* 鼠标悬停时的颜色 */
    animation:atguigu 1s;
  }
  .come{
    animation:atguigu 1s;
  }
  .go{
    animation:atguigu 1s reverse;
  }
  @keyframes atguigu{
    from{
      transform: translateY(100px);
    }
    to{
      transform: translateY(0px);
    }
  }
</style>
